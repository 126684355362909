import {apiClient as client} from '@/shared/service/api-client';

export default {
  async getStaffName(params) {
    return await client.get('/staff/getStaffSearchList', {params: params});
  },
  async getAllDepartments(params) {
    return await client.get('/staff/getDepartmentList', {params: params});
  },
  async search(params) {
    return await client.get('/staff/searchStaffList', {params: params});
  },
  async get(id) {
      return await client.get('/staff/get', {params: {id: id}});
  },
  async setRoleAdmin(params) {
    return await client.post('/staff/setRole/admin', params);
  },
  async setRoleStaff(params) {
    return await client.post('/staff/setRole/staff', params);
  },
  async setRoleDepartment(params) {
    return await client.post('/staff/setRole/department', params);
  },

  async closeStaff(id) {
    return await client.post('/staff/close', {id: id});
  },
  async openStaff(id) {
    return await client.post('/staff/open', {id: id});
  },

  async countClosedStaff(params) {
    return await client.get('/staff/countClosedStaff', {params: params});
  },
  async getStaffSearchListWithDept() {
    return await client.get('/staff/getStaffSearchListWithDept');
  },
  async getLastSyncTime(params) {
    return await client.get('/staff/getLastSyncTime', {params: params});
},
async asyncAllStaffs(params) {
  return await client.get('/staff/asyncAllStaffs', {params: params});
},
}
