<template>
  <div class="flex items-center">
    <a-avatar v-if="avatar?.avatar"
              class="flex-shrink-0"
              :shape="round ? 'circle' : 'square'"
              :size="size || 48"
              :src="avatar.avatar"
              alt="用户头像" />

    <svg-icon v-else
              :size="size || 48"
              type="iconmorentouxiang" />

    <div :class="{'ml-12': avatar?.name || avatar?.userId}"
         :style="{'max-width': `${width ? width : '160px'}`}">

      <p class="flex">
        <template v-if="!isUserId">
          <a-tooltip v-if="avatar?.name?.length > 5"
                     :title="avatar?.name">
            <span class="line-clamp-1 cursor-pointer break-all">{{ avatar?.name }}</span>
          </a-tooltip>
          <span v-else
                class="line-clamp-1 break-all">{{ avatar?.name }}</span>
        </template>

        <template v-else>
          <div v-is="'ww-open-data'"
               class="ww-open-data line-clamp-1 break-all"
               type="userName"
               :openid="avatar?.userId"></div>
        </template>

        <span class="name-suffix__wechat"
              v-if="userType === userTypeEnum.WECHAT">@微信</span>
        <span class="name-suffix__corp"
              :style="{'max-width': `${corpWidth ? corpWidth : '100px'}`}"
              v-if="userType === userTypeEnum.CORP">@{{ avatar?.corpName }}</span>
      </p>

      <p v-if="avatar?.nickname"
         class="line-clamp-1">昵称：{{ avatar?.nickname }}</p>

      <slot name="bottom"></slot>
    </div>

    <slot></slot>
  </div>
</template>

<script>
import { Avatar, Tooltip } from "ant-design-vue";
import SvgIcon from "@/components/SvgIcon";
import { userTypeEnum } from "@/global";

export default {
  name: "AvatarColumn",

  components: {
    AAvatar: Avatar,
    ATooltip: Tooltip,
    SvgIcon,
  },

  props: {
    avatar: {
      type: Object,
      default: () => ({
        avatar: "", // 头像地址
        name: "", // 真实姓名
        nickname: "", // 昵称
        corpName: "", // 企业名称，用来展示@corp的
      }),
    },
    size: Number, // 头像大小
    userType: [String, Number], // 微信： 1， 企业： 2
    round: Boolean,
    width: String,
    corpWidth: String,

    isUserId: {
      type: Boolean,
    },
  },

  setup() {
    return {
      userTypeEnum,
    };
  },
};
</script>