<template>
  <a-modal v-model:visible="modalVisible"
           :title="title"
           width="600px"
           :footer="null"
           @ok="handleOk"
           @cancel="$emit('update:visible', false)">
    <div class="share-modal">
      <div class="share-link">
        <div class="link__container">
          {{ item.link }}
        </div>
        <a-button class="main-btn"
                  type='primary'
                  @click='copy(item.link)'>复制分享链接</a-button>
      </div>
      <div class="share-qrcode">
        <div class="qrcode__container">
          <Qrcode :width="120"
                  :height="120"
                  :value="item.link"
                  :dotsOptions="{type: 'square', color: '#000'}"
                  :cornersSquareOptions="{ type: 'square', color: '#000000' }"
                  imgclass="download-img" />
        </div>
        <a-button class="main-btn"
                  type='primary'
                  @click='downloadQrcode(item.title)'>下载二维码</a-button>
      </div>
    </div>
    <slot />
  </a-modal>
</template>

<script>
import { defineComponent, ref, watch } from "vue";

import Qrcode from "qrcode-vue3";

import { copy, downloadImg } from "@/global";

export default defineComponent({
  name: "ShareLinkModal",
  props: {
    visible: Boolean,
    title: String,
    item: Object,
  },

  emits: ["update:visible"],

  components: {
    Qrcode,
  },

  setup(props) {
    const modalVisible = ref(false);
    watch(
      () => props.visible,
      (newVisible) => {
        modalVisible.value = newVisible;
      }
    );

    const downloadQrcode = function (fileName) {
      const img = document.getElementsByClassName("download-img");
      const src = img[0].getAttribute("src");

      downloadImg(src, fileName);
    };

    return {
      modalVisible,
      copy,
      downloadQrcode,
    };
  },
});
</script>

<style lang='less' scoped>
.share-modal {
  display: flex;
  .share-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 36px;
    .border-right();

    .link__container {
      padding: @spacing-4x;
      width: 240px;
      height: 120px;
      background-color: @bg-normal;
      border-radius: 8px;
      .text-14();
      color: @gray-darker;
      word-break: break-all;
      overflow-y: auto;
    }
  }

  .share-qrcode {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: @spacing-8x;
    width: 244px;

    .qrcode__container {
      margin: 0 auto;

      min-height: 120px;
      border-radius: 8px;
      .text-14();
      color: @gray-darker;
    }
  }

  .main-btn {
    width: 120px;
    margin-top: @spacing-6x;
    border-radius: @border-radius;
  }
}
</style>