<template>
  <div ref="searchHeader"
       class="search-header ">
    <a-form :model="searchForm"
            layout="inline">
      <template v-if="!isFromColumn">
        <a-form-item class="block"
                     label="直播状态">
          <a-select v-model:value="searchForm.liveStatus"
                    placeholder="全部"
                    allow-clear
                    style="width: 160px"
                    @change="onSearchSubmit">
            <a-select-option v-for="(status, name) in LiveStatusEnum"
                             :key="name"
                             :value="name">
              {{ status }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="直播讲师">
          <a-input v-model:value="searchForm.teacherNickname"
                   placeholder="请输入讲师名称"
                   style="width: 160px"
                   @change="searchLive" />
        </a-form-item>

        <a-form-item label="直播日期">
          <a-range-picker v-model:value="timeValue"
                          format="YYYY-MM-DD"
                          style="width: 280px"
                          @change="handleSetectTime" />
        </a-form-item>
      </template>

      <a-form-item label="直播主题">
        <a-input v-model:value="searchForm.title"
                 placeholder="请输入直播主题"
                 style="width: 280px"
                 @change="searchLive" />
      </a-form-item>
    </a-form>

    <a-button class="self-end"
              type="primary"
              @click="goToCreateLive">
      <svg-icon type="icontianjia1" />新建直播
    </a-button>
  </div>

  <div ref="liveTable">
    <a-table row-key="id"
             :columns="classColumns"
             :data-source="liveOpenclass"
             :scroll="{ y: 'calc(100vh - 423px)' }"
             :pagination="pagination"
             :loading="tableLoading"
             :customRow="customRow"
             @change="onPaginationChange">

      <template #liveTitle="{ record }">
        <p class="line-clamp-2">{{ record.title }}</p>
      </template>

      <template #teacher="{ record }">
        <template v-if="record.teacherSource === 'internal'">
          <avatar-column :avatar="{avatar: record.teacher.avatar, userId: record.teacher.workWechatThirdAppUserId}"
                         :size="32"
                         is-user-id>

            <a-popover :overlayStyle="{height: '300px'}"
                       trigger="click"
                       :visible="record.isEdit"
                       placement="right"
                       :getPopupContainer="() => $refs.liveTable"
                       @visibleChange="handleRecordEdit($event, record)">
              <template #content>
                <select-staff @choose="chooseInternalTeacher($event, record)" />
              </template>

              <svg-icon v-if="record.status === 'unstart'"
                        class="hidden ml-8 text-admin-primary cursor-pointer flex-shrink-0 edit-btn"
                        type="iconbianji" />
            </a-popover>
          </avatar-column>
        </template>

        <template v-if="record.teacherSource === 'external'">
          <div v-if="record.teacherId">
            <avatar-column :avatar="record.teacher"
                           :size="32">
              <svg-icon v-if="record.status === 'unstart'"
                        class="hidden ml-8 text-admin-primary cursor-pointer flex-shrink-0 edit-btn"
                        type="iconbianji"
                        @click="setLiveTeacher(record)" />
            </avatar-column>
          </div>
          <a-button v-else
                    type="link"
                    :disabled="record.status !== 'unstart'"
                    @click="setLiveTeacher(record)">设置讲师</a-button>
        </template>
      </template>

      <template #hours="{ record }">
        {{ getDuration(record) }}
      </template>

      <template #actualStartTime="{ record }">
        {{ record.actualStartTime ? $f.datetime(record.actualStartTime, 'YYYY-MM-DD HH:mm') : $f.datetime(record.startTime, 'YYYY-MM-DD HH:mm') }}
      </template>

      <template #status="{ text }">
        <a-tag v-if="text === 'unstart'"
               class="px-8 py-4 border-red-4 bg-red-1 text-red-4">{{ LiveStatusEnum[text] }}</a-tag>
        <a-tag v-else-if="text === 'living'"
               class=" px-8 py-4 border-green-5 bg-green-1 text-green-5">{{ LiveStatusEnum[text] }}</a-tag>
        <a-tag v-else-if="text === 'finished'"
               class="px-8 py-4 border-color-eb bg-color-f5 text-color-999">{{ LiveStatusEnum[text] }}</a-tag>
        <span v-else> - </span>
      </template>

      <template #action="{record}">
        <router-link :to="{name: 'liveOpenClass_statistic', query: {id: record.id, setId: record.setId}}">
          <a-button type="link">详情</a-button>
        </router-link>

        <a-button class="ml-16"
                  type="link"
                  @click="shareLiveRoom(record)">分享</a-button>

        <a-dropdown>
          <svg-icon class="ml-16 text-16 text-admin-primary"
                    type="iconmore" />

          <template #overlay>
            <a-menu>
              <a-menu-item v-if="record.status !== 'finished' && record.teacherId"
                           @click="toLive(record)">
                去直播
              </a-menu-item>

              <a-menu-item v-if="record.status === 'unstart'">
                <router-link :to="{name: 'liveOpenClass_create', query: {isEdit: true, id: record.id }}">
                  编辑
                </router-link>
              </a-menu-item>

              <a-menu-item v-if="record.status !== 'living'"
                           @click='openDeleteDialog(record)'>
                删除
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>
    </a-table>
  </div>

  <a-modal v-model:visible="externalTeacherModalVisible"
           title="邀请外部讲师"
           :footer="null"
           :getContainer="() => $refs.liveTable">
    <p class="text-color-999">将以下链接发送到讲师微信，讲师确认后即邀请成功。</p>
    <div class="my-16 p-16 border-radius bg-color-f5">{{ externalTeacher.shareLink }}</div>
    <a-button class="block mx-auto"
              type="primary"
              v-copy="externalTeacher.shareLink">复制邀请链接</a-button>
  </a-modal>

  <a-modal v-model:visible="confirmDeleteExternalTeacherModalVisible"
           title="重新设置讲师"
           :getContainer="() => $refs.liveTable"
           @ok="deleteExternalTeacher">
    <p class="text-color-999">确定要重新设置讲师？点击「确定」后删除当前讲师，并生成新的邀请链接。</p>
  </a-modal>

  <a-modal v-model:visible="balanceModalVisible"
           class="balance-modal"
           title="提醒"
           width="400px"
           :footer="null"
           :getContainer="() => $refs.searchHeader">
    <p class="mb-24 text-color-999">直播余额不足，请充值</p>
    <div class="text-right">
      <router-link :to="{name: 'system_setting_live_recharge'}">
        <a-button type="primary">去充值</a-button>
      </router-link>
    </div>
  </a-modal>

  <delete-modal v-model:visible="deleteModalVisible"
                title="删除直播公开课"
                :delete-item="deleteItem"
                @delete="deleteLiveRoom" />

  <a-modal v-model:visible="shareModalVisible"
           title="分享直播公开课"
           :footer="null"
           width="500px">
    <p class="mb-12 text-color-999">你可以用以下任一方式分享直播课</p>
    <div class="flex items-center">
      <Qrcode :width="120"
              :height="120"
              :margin="0"
              :value="shareLive.link"
              :dotsOptions="{type: 'square', color: '#000'}"
              :cornersSquareOptions="{ type: 'square', color: '#000000' }"
              imgclass="download-img" />
      <div class="flex flex-col items-start ml-8"
           style="line-height: 32px;">
        <a-button type="link"
                  v-copy="shareLive.link">
          1. 复制分享链接
        </a-button>

        <a-button type="link"
                  @click="downloadQrcode(shareLive.title)">
          2. 下载二维码
        </a-button>
        <a-button type="link"
                  @click="messageShare">
          3. 安排员工群发给客户
        </a-button>
      </div>
    </div>
    <p class="mb-12 text-color-999">微信扫码进入直播间， 然后分享直播</p>
  </a-modal>

  <a-modal v-model:visible="toLiveModalVisible"
           title="授课链接"
           :footer="null"
           width="500px">
    <div class="p-16 w-full bg-color-f5 border-radius"
         style="height: 120px;">{{ toLiveRoom.link }}</div>
    <div class="my-12 text-14 text-color-999">将上面链接发送给讲师和助教，他们用电脑浏览器进入直播间上课。</div>
    <div class="flex justify-end">
      <a-button type="primary"
                v-copy="toLiveRoom.link">复制链接</a-button>
      <a-button class="ml-24"
                type="primary"
                target="_blank"
                :href="toLiveRoom.link">进入直播间</a-button>
    </div>
  </a-modal>
</template>

<script>
import { defineComponent, ref, reactive, computed } from "vue";
import { DatePicker, message, Popover } from "ant-design-vue";
import dayjs from "dayjs";
import _ from "lodash";
import router from "@/router";
import Qrcode from "qrcode-vue3";
import SvgIcon from "@/components/SvgIcon";
import AvatarColumn from "@/components/AvatarColumn";
import SelectStaff from "@/components/SelectStaff";
import DeleteModal from "@/components/DeleteModal";

import liveApi from "@/service/api/live";
import { timestampToHour, downloadImg, getWxAgentConfig } from "@/global";
import usePaginationQuery from "@/shared/composables/usePaginationQuery";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "LiveOpenClassIndex",

  components: {
    ARangePicker: DatePicker.RangePicker,
    APopover: Popover,

    Qrcode,

    SvgIcon,
    AvatarColumn,
    SelectStaff,
    DeleteModal,
  },

  setup() {
    const route = new useRoute();
    const { setId } = route.query;
    // 是否来自直播专栏
    const isFromColumn = computed(() => {
      return setId;
    });

    // 搜索直播
    const timeValue = ref([]);

    const LiveStatusEnum = {
      unstart: "未开始",
      living: "进行中",
      finished: "已结束",
    };

    const searchForm = reactive({
      liveStatus: null,
      startTime: "",
      endTime: "",
      teacherNickname: "",
      title: "",
      deleted: 0,
      setId,
    });

    const searchLive = _.debounce(() => {
      onSearchSubmit();
    }, 500);

    const handleSetectTime = (res) => {
      if (res.length > 0) {
        searchForm.startTime = dayjs(res[0]._d).startOf("date").valueOf();
        searchForm.endTime = dayjs(res[1]._d).endOf("date").valueOf();
      } else {
        searchForm.startTime = "";
        searchForm.endTime = "";
      }
      onSearchSubmit();
    };

    // 直播列表
    const classColumns = [
      {
        title: "直播主题",
        dataIndex: "title",
        slots: {
          customRender: "liveTitle",
        },
      },
      {
        title: "讲师",
        dataIndex: "teacher",
        slots: {
          customRender: "teacher",
        },
      },
      {
        title: "时长",
        dataIndex: "hours",
        slots: {
          customRender: "hours",
        },
      },
      {
        title: "观看人数",
        dataIndex: "viewerTotalNum",
        width: "120px",
      },
      {
        title: "评论数",
        dataIndex: "chatTotalNum",
        width: "120px",
      },
      {
        title: "直播开始时间",
        dataIndex: "actualStartTime",
        slots: {
          customRender: "actualStartTime",
        },
      },
      {
        title: "状态",
        dataIndex: "status",
        slots: {
          customRender: "status",
        },
      },
      {
        title: "操作",
        key: "action",
        slots: {
          customRender: "action",
        },
      },
    ];

    const {
      rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,
    } = usePaginationQuery(router, searchForm, liveApi.searchRoom);

    // 设置讲师
    const externalTeacherModalVisible = ref(false);
    const confirmDeleteExternalTeacherModalVisible = ref(false);

    const externalTeacher = reactive({
      shareLink: "",
    });
    const editExternalLive = reactive({});

    function setLiveTeacher(record) {
      if (record.teacherId) {
        _.assign(editExternalLive, record);
        confirmDeleteExternalTeacherModalVisible.value = true;
      } else {
        externalTeacherModalVisible.value = true;
      }

      externalTeacher.shareLink =
        "https://" +
        window.location.host +
        "/customer-h5/" +
        "live/invite?externalTicket=" +
        record.externalTicket +
        "&corpId=" +
        record.corpId;
    }

    const deleteExternalTeacher = async () => {
      await liveApi
        .resetExternalTeacher({
          id: editExternalLive.id,
        })
        .catch(() => {
          message.error("删除讲师失败，请重试");
          return;
        });

      fetchPaginationData();

      confirmDeleteExternalTeacherModalVisible.value = false;
      externalTeacherModalVisible.value = true;
    };

    // 时长
    function getDuration(record) {
      if (record.status === "living") {
        return " - ";
      }

      if (!record.actualStartTime && record.actualEndTime) {
        return " - ";
      }

      let duration = undefined;

      if (!record.actualStartTime && !record.actualEndTime) {
        duration = record.endTime - record.startTime;
      } else if (record.actualStartTime && !record.actualEndTime) {
        duration = record.endTime - record.actualStartTime;
      } else {
        duration = record.actualEndTime - record.actualStartTime;
      }

      const timeFormat = timestampToHour(duration);
      return (
        timeFormat.hours + ":" + timeFormat.minutes + ":" + timeFormat.seconds
      );
    }

    // hover编辑图标
    const customRow = () => {
      return {
        onMouseenter: (event) => {
          event.target.className =
            "ant-table-row ant-table-row-level-0 display-btn";
        },

        onMouseleave: (event) => {
          event.target.className = "ant-table-row ant-table-row-level-0";
        },
      };
    };

    const handleRecordEdit = (visible, record) => {
      record.isEdit = visible;
    };

    const formateEditLiveParams = (teacher, record) => {
      const {
        id,
        title,
        startTime,
        endTime,
        summary,
        teacherSource,
        assistants,
        goods,
      } = record;

      const live = {
        id,
        title,
        startTime,
        endTime,
        summary,
        teacherSource,
      };

      live.teacherId = teacher.id;
      live.goodsId = goods?.id;
      live.assistantIds = [];

      _.forEach(assistants, (item) => {
        live.assistantIds.push(item.id);
      });

      return live;
    };

    const chooseInternalTeacher = async (teacher, record) => {
      const live = formateEditLiveParams(teacher, record);

      await liveApi.updateRoom(live).catch(() => {
        message.error("编辑失败，请重试");
        return;
      });

      record.isEdit = false;
      message.success("编辑成功");
      fetchPaginationData();
    };

    // 判断充值余额
    const balanceModalVisible = ref(false);

    const goToCreateLive = async () => {
      const res = await liveApi.timeUsage();

      if (res.remainTime <= 0) {
        balanceModalVisible.value = true;
        return;
      }

      router.push({
        name: "liveOpenClass_create",
        query: {
          setId,
        },
      });
    };

    // 删除直播
    const deleteModalVisible = ref(false);

    const deleteItem = reactive({});

    const openDeleteDialog = (record) => {
      _.assign(deleteItem, record);
      deleteModalVisible.value = true;
    };

    const deleteLiveRoom = async () => {
      await liveApi
        .deleteRoom({
          id: deleteItem.id,
        })
        .catch(() => {
          message.error("删除失败，请重试");
          return;
        });

      message.success("删除成功");
      fetchPaginationData();
      deleteModalVisible.value = false;
    };

    // 分享直播
    const shareModalVisible = ref(false);
    const shareLive = reactive({
      title: "",
      link: "",
    });

    const shareLiveRoom = function (record) {
      _.assign(shareLive, record);
      shareModalVisible.value = true;

      shareLive.title = record.title;
      shareLive.link = `https://${window.location.host}/customer-h5/livePlay?externalTicket=${record.externalTicket}&corpId=${record.corpId}`;
    };

    const downloadQrcode = function (fileName) {
      const img = document.getElementsByClassName("download-img");
      const src = img[0].getAttribute("src");

      downloadImg(src, fileName);
    };

    const messageShare = async () => {
      // 判断是否已创建过群发任务
      const isShared = await liveApi.checkMemberShareLive({
        roomId: shareLive.id,
      });

      if (isShared) {
        router.push({
          path: "/marketingUtil/messageShareIndex",
          query: {
            roomId: shareLive.id,
            setId: shareLive.setId,
          },
        });
        return;
      }

      router.push({
        path: "/marketingUtil/messageShareCreate",
        query: {
          roomId: shareLive.id,
          setId: shareLive.setId,
        },
      });
    };

    // 授课链接
    const toLiveModalVisible = ref(false);
    const toLiveRoom = reactive({});

    const toLive = (record) => {
      toLiveModalVisible.value = true;
      toLiveRoom.link =
        "https://" + document.domain + "/console-pc/live/enter?id=" + record.id;
    };

    getWxAgentConfig();

    return {
      isFromColumn,
      currentTime: dayjs().valueOf(),
      searchForm,
      searchLive,
      handleSetectTime,

      classColumns,
      liveOpenclass: rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,

      externalTeacherModalVisible,
      confirmDeleteExternalTeacherModalVisible,
      externalTeacher,
      setLiveTeacher,
      deleteExternalTeacher,

      getDuration,

      timeValue,
      LiveStatusEnum,
      customRow,

      handleRecordEdit,
      chooseInternalTeacher,

      deleteModalVisible,
      deleteItem,
      openDeleteDialog,
      deleteLiveRoom,

      shareModalVisible,
      shareLive,
      shareLiveRoom,
      downloadQrcode,
      messageShare,

      toLiveModalVisible,
      toLiveRoom,
      toLive,

      balanceModalVisible,
      goToCreateLive,
    };
  },
});
</script>

<style lang='less' scoped>
.display-btn {
  .edit-btn {
    display: unset;
  }
}
</style>
