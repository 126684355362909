<template>
  <div class="search-header">
    <a-form layout="inline"
            :model="searchForm">
      <a-form-item>
        <a-input v-model:value="searchForm.name"
                 placeholder="请输入观众昵称"
                 style="width: 360px"
                 v-debounce="onSearchSubmit" />
      </a-form-item>
    </a-form>

    <a-button type="primary"
              style="width: 146px;height: 36px"
              :disabled="viewerList.length === 0"
              :loading="lodash.includes(['pending', 'downloading'], process.processStatus)"
              @click="handleExport">
      {{ exprotStatus }}
    </a-button>
  </div>

  <a-table :columns="viewerColumns"
           :data-source="viewerList"
           row-key="id"
           :pagination="pagination"
           :scroll="{ y: '100vh' }"
           :loading="tableLoading"
           bordered
           @change="onPaginationChange">
    <template #memberInfo="{ record }">
      <avatar-column :avatar="{ avatar: record.memberInfo.memberAvatar, name: record.memberInfo.memberName }" />
    </template>

    <template #watchDurationSum="{ text }">
      {{ getDuration(text) }}
    </template>

    <template #action="{ record }">
      <template v-if="record.memberInfo.targetSource === 'guest'">
        <router-link
                     :to="{path:'/customer/customerDetail',query:{ customerId: record.memberInfo.customerId, id: record.memberInfo.customerRelationId}}">
          <a-button type="link"
                    :disabled="!record.memberInfo.customerRelationId">客户详情</a-button>
        </router-link>
      </template>

      <template v-else-if="record.memberInfo.targetSource === 'staff'">
        <router-link :to="{path:'/enterprise/memberDetail',query:{ id: record.memberInfo.staffId}}">

          <a-button type="link">员工详情</a-button>
        </router-link>
      </template>

    </template>
  </a-table>
</template>

<script>
import { defineComponent, ref, reactive } from "vue";
import { message } from "ant-design-vue";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";

import liveApi from "@/service/api/live";
import { timestampToHour } from "@/global";

import AvatarColumn from "@/components/AvatarColumn";

import { apiStore } from "@/shared/service/api-client";
import usePaginationQuery from "@/shared/composables/usePaginationQuery";

export default defineComponent({
  name: "LiveStatisticViewData",

  components: {
    AvatarColumn,
  },

  setup() {
    const route = new useRoute();
    const router = new useRouter();

    const { setId } = route.query;

    const getDuration = function (text) {
      const time = text;
      const timeFormat = timestampToHour(time);

      return `${Number(timeFormat.hours) ? `${timeFormat.hours}时` : ""}${
        Number(timeFormat.minutes) ? `${timeFormat.minutes}分` : ""
      }${timeFormat.seconds}秒`;
    };

    // 观众数据表格
    const viewerColumns = [
      {
        title: "观众",
        dataIndex: "memberInfo",
        slots: { customRender: "memberInfo" },
      },
      {
        title: "观看课程数",
        dataIndex: "liveNumber",
      },
      {
        title: "观看时长",
        dataIndex: "watchDurationSum",
        slots: { customRender: "watchDurationSum" },
      },
      {
        title: "操作",
        dataIndex: "action",
        slots: { customRender: "action" },
      },
    ];

    const searchForm = reactive({
      setId,
      name: "",
    });

    const {
      rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,
    } = usePaginationQuery(router, searchForm, liveApi.getSetStudentList);

    // 导出观众数据
    const exprotStatus = ref("导出");

    const process = reactive({
      processStatus: "unstart",
    });

    const handleExport = () => {
      if (_.includes(["unstart", "downloaded"], process.processStatus)) {
        exportedViewerData();
        return;
      }

      if (process.processStatus === "exported") {
        downloadViewrData();
      }
    };

    const exportedViewerData = async () => {
      await liveApi.asyncSetExport({
        setId,
        name: searchForm.name,
      });
      exprotStatus.value = "导出中..";
      checkProcessStatus();
    };

    const timer = ref();
    const checkProcessStatus = () => {
      let i = 0;
      timer.value = setInterval(async () => {
        i++;
        if (i >= 60) {
          clearInterval(timer.value);
        }
        const res = await liveApi.getSetLastExportStatus();
        _.assign(process, res);

        if (process.processStatus === "exported") {
          message.success("导出成功");
          exprotStatus.value = "下载";

          clearInterval(timer.value);
        }

        if (process.processStatus === "downloaded") {
          message.success("下载成功");
          exprotStatus.value = "导出";

          clearInterval(timer.value);
        }
      }, 1000);
    };

    const downloadViewrData = async () => {
      let url =
        "https://" +
        window.location.host +
        "/api/console/live/room/download?id=" +
        process.id +
        "&token=" +
        apiStore.token;

      window.location.href = url;
      checkProcessStatus();
    };

    return {
      lodash: _,
      viewerColumns,
      searchForm,

      getDuration,

      viewerList: rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,

      exprotStatus,
      process,
      timer,
      handleExport,
    };
  },
});
</script>