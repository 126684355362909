<template>
  <a-spin :spinning='spinning'>
    <router-header :routerHeaderInfo="routerHeaderInfo" />

    <div class="flex relative mr-16 px-16 py-12 w-min-2/3 bg-color-f5">

      <a-image class="flex-shrink-0"
               :src="column.cover"
               alt="专栏封面"
               style="width: 284px; aspect-ratio: 16 / 9; border-radius: 8px;"
               :fallback="require('@/assets/ErrorImg.png')" />

      <div class="flex-1 flex flex-col ml-8">
        <template v-if="column.name?.length > 30">
          <a-tooltip :title="column.name">
            <p class="text-16 text-color-333 font-medium truncate">{{ column.name }}</p>
          </a-tooltip>
        </template>
        <p v-else
           class="text-16 text-color-333 font-medium truncate">{{ column.name }}</p>

        <div class="flex my-8 w-full h-84 text-color-999">
          <span class="flex-shrink-0 w-44">简介:</span>
          <p class="text-color-333 break-all overflow-y-auto"> {{ column?.summary || '-' }}</p>
        </div>

        <div class="self-end mt-8">
          <router-link :to="{path: '/marketingUtil/liveColumnCreate', query: {setId: $route.query.setId}}">
            <a-button class="mr-12">
              编辑专栏
            </a-button>
          </router-link>

          <a-button @click="shareModalVisible = true">分享</a-button>
        </div>
      </div>

    </div>

    <a-tabs v-model:activeKey="showType">
      <a-tab-pane key="LiveClass"
                  tab="内容管理" />
      <a-tab-pane key="ViewData"
                  tab="观看数据" />
    </a-tabs>

    <keep-alive>
      <component :is="componentsList[showType]"
                 :key="showType" />
    </keep-alive>
  </a-spin>

  <share-modal v-model:visible="shareModalVisible"
               title="分享专栏"
               :item="shareLive" />
</template>

<script>
import { defineComponent, ref, reactive, computed } from "vue";
import { Statistic, Tooltip, Tabs, Image } from "ant-design-vue";
import { useRoute } from "vue-router";
import _ from "lodash";
import Qrcode from "qrcode-vue3";

import RouterHeader from "@/components/RouterHeader.vue";
import ViewData from "@/views/liveOpenClass/liveColumn/LiveColumnViewData";
import LiveClass from "@/views/liveOpenClass/liveClass/LiveClassIndex";
import ShareModal from "@/components/ShareLinkModal";

import liveApi from "@/service/api/live";

export default defineComponent({
  name: "LiveOpenClassStatistic",

  components: {
    AStatistic: Statistic,
    ATooltip: Tooltip,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    AImage: Image,
    Qrcode,

    RouterHeader,
    ViewData,
    LiveClass,
    ShareModal,
  },

  setup() {
    const spinning = ref(true);
    const route = new useRoute();
    const { setId } = route.query;

    const routerHeaderInfo = [
      {
        path: "/marketingUtil/liveOpenClassIndex",
        name: "直播专栏",
        query: {
          active: "column",
        },
      },
      {
        name: "专栏详情",
      },
    ];

    const column = reactive({});

    const getColumnInfo = async () => {
      const res = await liveApi.getLiveRoomSet({
        id: setId,
      });

      _.assign(column, res);

      spinning.value = false;
    };

    getColumnInfo();

    const showType = ref("LiveClass");

    const componentsList = {
      ViewData,
      LiveClass,
    };

    // 分享直播
    const shareModalVisible = ref(false);
    const shareLive = computed(() => {
      return {
        title: column.name,
        link: `https://${window.location.host}/customer-h5/live/column?externalId=${column.externalId}&corpId=${column.corpId}`,
      };
    });

    return {
      lodash: _,
      spinning,

      routerHeaderInfo,
      column,

      showType,
      componentsList,

      shareModalVisible,
      shareLive,
    };
  },
});
</script>
